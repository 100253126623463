
import Credit from '@/components/Layout/Credit/landing-hero-image-credit';

import ImageSources from '@/mixins/srcset';

export default {
  components: {
    Credit,
  },
  mixins: [ImageSources],
  layout: 'default',
  async head() {
    return {
      script: [
        {
          json: await this.$content('pocketpasta').fetch(),
          type: 'application/ld+json',
        },
      ],
      link: [],
    };
  },
};
